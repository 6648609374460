
h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
}
.cont-footer-princ {
 /* left: 30px !important;  */
}

.cont-imagen1  {
    /* width: 100%;
    margin: 0px; */
    
  }
  
  #img1 {
    position: relative;
    width: 350px;
    height: 280px;
    top: 30px;
    border:10px solid #7FFFD4; 
    /* box-shadow: 0 2px 30px 0 rgba(43,187,173, 0.8);
    transition: 0.3s; */
  }
  #img2 {
    position: absolute;
    width: 350px;
    height: 280px;
    top: 230px;
    left : 180px;
    z-index: 2;
    border:10px solid #7FFFD4; 
    /* box-shadow: 0 2px 30px 0 rgba(43,187,173, 0.8);
    transition: 0.3s; */
  }
  #img3 {
    position: absolute;
    width: 350px;
    height: 280px;
    top: 400px;
    left : 15px;
    z-index: 3;
    border:10px solid #7FFFD4; 
    /* box-shadow: 0 2px 30px 0 rgba(43,187,173, 0.8);
    transition: 0.3s; */
  }
  #img4 {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 5px;
    right: 5%;
    z-index: 3;
  }
  #img5 {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 30px;
    right: 0px;
    z-index: 3;
    transform: rotate(10deg);
  }
  #img6 {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 30%;
    right: 13%;
    z-index: 3;
  }
  #img7 {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 35%;
    right:15%;
    z-index: 3;
  }
  #img8 {
    position: absolute;
    width: 160px;
    height: 180px;
    top : 65%;
    right: 0px;
    z-index: 1;
    transform: rotate(30deg);
  }
  #img9 {
    position: absolute;
    width: 150px;
    height: 180px;
    top: 77%;
    right: 2%;
    z-index: 3;
    transform: rotate(-30deg);
  }
  #img10 {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 0px;
    left: 25%;
    z-index: 3;
    transform: rotate(20deg);
  }
  .titulo {
    margin-top: 40px;
     text-align: center;
     color: #070707;
     font-size: 30px;
     margin-bottom: 70px;
     /* margin-left: 50px; */
     font-weight: bold;
 }

 #cuerpotexto2, #cuerpotexto3, #cuerpotexto4 {
   color: #FF69B4;
 } 

 #cuerpotexto5 {
  color: #070707;
  font-size: 15px;
} 
 .cuerpotexto1 {
  text-align: justify;
 }
.cuerpotexto11 :hover {
  color: #2bbbad !important;
  font-weight: bold !important;
}  

 .planilla {
  color: red;
}
.planilla1 :hover {
  color: #2bbbad !important;
  font-weight: bold !important;
}  
.planilla1 {
  color: #070707 !important;
  font-weight: bold !important;
}


