
h1{
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

}
.cont-footer-princ {
left: 30px !important; 
}

.cont-servi-principal  {
  width: 100%;
  margin: 0px;
  
}

#img1pri {
  position: relative;
  width: 350px;
  height: 280px;
  z-index: 2;
  top: 30px;
  /* border:10px solid #7FFFD4;  */
  /* box-shadow: 0 2px 30px 0 rgba(43,187,173, 0.8);
  transition: 0.3s; */
}
#img2pri {
  position: absolute;
  width: 350px;
  height: 280px;
  top: 340px;
  /* left : 0px; */
  z-index: 2;
  /* border:10px solid #7FFFD4;  */
  /* box-shadow: 0 2px 30px 0 rgba(43,187,173, 0.8);
  transition: 0.3s; */
}
#img3pri {
  position: absolute;
  width: 350px;
  height: 300px;
  top: 180px;
  left : 380px;
  z-index: 1;
  /* border:10px solid #7FFFD4;  */
  /* box-shadow: 0 2px 30px 0 rgba(43,187,173, 0.8);
  transition: 0.3s; */
}
#img4pri {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -5px;
  left: 150px;
  z-index: 3;
  transform: rotate(-10deg);
}
#img5pri {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 143px;
  left: 530px;
  z-index: 3;
  transform: rotate(10deg);
}
#img6pri {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 305px;
  left: 150px;
  z-index: 3;
  transform: rotate(-10deg);
}
#img7pri {
  position: absolute;
  width: 250px;
  height: 250px;
  top: 520px;
  right:-140px;
  z-index: 3;
}
#img8pri {
  position: absolute;
  width: 260px;
  height: 225px;
  top : 20px;
  left: 395px;
  z-index: 4;
  /* transform: rotate(30deg); */
}
#img9pri {
  position: absolute;
  width: 100px;
  height: 95px;
  top : -15px;
  left: 108px;
  z-index: 4;
  /* transform: rotate(30deg); */
}
#img10pri {
  position: absolute;
  width: 180px;
  height: 180px;
  top : 510px;
  left: 380px;
  z-index: 4;
  transform: rotate(50deg); 
}
.titulopri {
  position: absolute;
  margin-top: 20px;
   text-align: left;
   color: #070707;
   font-size: 40px;
   margin-bottom: 50px;
   margin-left: -150px;
   font-weight: bold;
}
.cuerpo-textopri {
  margin-top: 100px;
  width: 450px;
 
}

#cuerpotexto2pri, #cuerpotexto3pri, #cuerpotexto4pri, #cuerpotexto5pri, #cuerpotexto6pri, #cuerpotexto7pri, #cuerpotexto8pri {
 color: #FF69B4;
} 
.cuerpotexto1pri {
text-align: justify;
}

