@font-face {
  font-family: 'GreatVibes-Regular';
  src: local('GreatVibes-Regular'), url(../../fonts/great-vibes/GreatVibes-Regular.ttf) format('truetype');
}

h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
}
.cont-home-princ {
  width:100%;
  text-align: center;

}
.cont-home1  {
   margin:0px auto; 
    width: 80px;
    margin-top: 30px;
   
   
  }
   
.cb-centermode {
    margin-bottom: 20px;
    display: block;
  }
  .cb-centermode input {
    margin-right: 7px;
  }
   
  /* card style start */
   
  .img-card {
    border-radius: 4px;
    background: #fff;
    color: #666;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(43,187,173, 0.5);
    transition: 0.3s;
  }
   
  .img-card:hover {
    box-shadow: 0 4px 8px 0 rgba(43,187,173, 0.5);
  }
   
  .img1 {
    width: 100%;
    height: 450px;
    object-fit: unset;
    /* object-fit: cover; */
  }

  .img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  } 
  .card-body {
    padding: 15px;
  }
   
  .card-title {
    font-size: 20px;
    font-weight: 600;
    color: #070707;
  }
   
  .card-text {
    margin-top: 10px;
    color: #c40075;
  }
   
  /* card style end */
   
   
  /* carousel slider style start */
   
  .slick-slide > div {
    margin: 0 10px;
  }
   
  .slick-list {
    margin: 0 -10px;
  }
   
  .slick-slide *:focus{
    outline: none;
  }
  .slick-prev:before {
    color: #2bbbad !important;
}
.slick-next:before {
    color: #2bbbad !important;
}
.card{
    border: none !important;
}
.eslogan {
   margin-top: 40px;
    text-align: center;
    font-size: 50px;
    color: #070707;
    margin-bottom: 50px;
    /* margin-left: 50px; */
}
.biblico {
  font-family: GreatVibes-Regular;
  color: #070707;
  font-size: 80px;
  text-align: center;
}
.biblico1 {
  font-family: GreatVibes-Regular;
  color: #070707;
  font-size: 80px;
  text-align: center;
  margin-bottom: -50px;
  
}
.biblico2 {
  font-family: GreatVibes-Regular;
  color: #070707;
  font-size: 80px;
  text-align: left;
  margin-left: 90px;
  margin-bottom: -50px;
}

.biblico3 {
  font-family: GreatVibes-Regular;
  color: #070707;
  font-size: 40px;
  text-align: right;
  margin-right: 200px;
}
.teh { 
  text-align: justify;
}