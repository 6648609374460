
h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
}

  #xcard1f{
    width: 350px;
    height: 320px;
    box-shadow: 0 2px 4px 0 rgba(43,187,173, 0.5);
    transition: 0.5s;

  }
  #xcard1f:hover {
    z-index: 10;
    box-shadow: 0 4px 8px 0 rgba(43,187,173, 0.5);
    transform: scale(1.5);
  }

  #cardp {
    width: 18rem;
  }

  #card-group2 {
    margin-top: 15px;
  }

  #card-group3 {
    margin-top: 15px;
  }
  #card-group4 {
    margin-top: 15px;
  }
  #card-group5 {
    margin-top: 15px;
  }
  #card-group6 {
    margin-top: 15px;
  }
  #card-group7 {
    margin-top: 15px;
  }
