h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
}
.cont-footer-princ {
 left: 30px !important; 
}

.contenedor-cedula  {
    width: 100%;
    margin: 0px;
    
  }
.titulo-pago {
    line-height: 30px;
    background-color: #2bbbad;
    font-size: 20px;
    text-align: center;
    color: #050505;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .labelcedulac  {
    font-size: 18px;
    color: #050505;
    font-weight: bold;
    
  }
  .contenedor-cedula, .contenedorcedula1, .contenedorcedula2, .cont-radio {
    text-align: center;
      display: inline-block;
  }
  
  .contenedorcedula1{
      margin-top: 20px;
  }
  .labelcedulac1  {
    font-size: 18px;
    color: #050505;
    font-weight: bold;
    margin-right: 20px;
    
  }
  .opnivel2 {
    font-size: 20px;
    margin-left: 20px;
  }