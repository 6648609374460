
h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code { 
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
}
.cont-footer-princ {
 left: 30px !important; 
}

.cont-nosotros-principal  {
    width: 100%;
    margin: 0px;
  }
  
  #xcard1n{
    width: 230px;
    height: 230px;
    box-shadow: 0 2px 4px 0 rgba(43,187,173, 0.5);
    transition: 0.5s;
    margin-bottom: 15px;

  }
  #xcard1n:hover {
    box-shadow: 0 4px 8px 0 rgba(43,187,173, 0.5);
  }
.tn {
  font-size: 20px;
 text-align: justify;
 margin-bottom: 35px;
}
.tn1 {
  font-size: 20px;
 text-align: justify;
} 

.tmision, .tvision, .tvalores {
  font-size: 30px;
}