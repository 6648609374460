
h1{
  text-align: center;
} 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

}
.cont-footer-princ {
/* left: 30px !important;  */
}

.cont-servi-principal  {
  /* width: 100%;
  margin: 0px; */
  
}

#img1pe {
  position: relative;
  width: 300px;
  height: 280px;
  z-index: 2;
  top: 30px;
  /* border:10px solid #7FFFD4;  */
  /* box-shadow: 0 2px 30px 0 rgba(43,187,173, 0.8);
  transition: 0.3s; */
}
#img2pe {
  position: absolute;
  width: 320px;
  height: 250px;
  top: 135px;
  left : 370px;
  z-index: 2;
  /* border:10px solid #7FFFD4;  */
  /* box-shadow: 0 2px 30px 0 rgba(43,187,173, 0.8);
  transition: 0.3s; */
}
#img3pe {
  position: absolute;
  width: 410px;
  height: 350px;
  top: 260px;
  left : 80px;
  z-index: 1;
  /* border:10px solid #7FFFD4;  */
  /* box-shadow: 0 2px 30px 0 rgba(43,187,173, 0.8);
  transition: 0.3s; */
}
#img4pe {
  position: absolute;
  width: 120px;
  height: 140px;
  top: 300px;
  left: -20px;
  z-index: 1;
  transform: rotate(-10deg);
}
#img5pe {
  position: absolute;
  width: 120px;
  height: 120px;
  top: 400px;
  left: -15px;
  z-index: 1;
  transform: rotate(10deg);
}
#img6pe {
  position: absolute;
  width: 120px;
  height: 120px;
  top: 490px;
  left: -20px;
  z-index: 1;
  transform: rotate(-10deg);
}
#img7pe {
  position: absolute;
  width: 250px;
  height: 250px;
  top: 520px;
  margin-left:60%;
  z-index: 3;
}
#img8pe {
  position: absolute;
  width: 260px;
  height: 225px;
  top : 20px;
  left: 395px;
  z-index: 4;
  /* transform: rotate(30deg); */
}
#img9pe {
  position: absolute;
  width: 100px;
  height: 95px;
  top : -15px;
  left: 108px;
  z-index: 4;
  /* transform: rotate(30deg); */
}
#img10pe {
  position: absolute;
  width: 180px;
  height: 180px;
  top : 510px;
  left: 380px;
  z-index: 4;
  transform: rotate(50deg); 
}
.titulope{
  position: absolute;
  margin-top: 20px;
   text-align: left;
   color: #070707;
   font-size: 40px;
   margin-bottom: 50px;
   margin-left: -150px;
   font-weight: bold;
}
.cuerpo-textope {
  margin-top: 100px;
  width: 450px;

}

#cuerpotexto2pe, #cuerpotexto3pe, #cuerpotexto4pe {
 color: #FF69B4;
} 
.cuerpotexto1pe {
text-align: justify;
}

