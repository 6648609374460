.contfooter {
    margin-top: 1rem;
    background-color: #2bbbad;
    height: 300px;    
}

.logo {
    margin-top: 10px;
}

.logof {
     width: 100%;
     left: 0;
}

.socialesf {
    text-align: left;
}



.contac {
    margin-top: 20%;
    color: rgb(12, 11, 11);
    font-size: 25px;
    text-align: left;
}

.datos {
    color: rgb(12, 11, 11);;
    font-size: 15px;
    text-align: left;
    margin-bottom: 2px;

}