h1{
    text-align: center;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
  }
  .cont-footer-princ {
  left: 30px !important; 
  }
  
  .cont-servi-principal  {
    width: 100%;
    margin: 0px;
    
  }
  
  .titulocon {
    
    margin-top: 20px;
     text-align: center;
     margin-left: 35px;
     color: #070707;
     font-size: 40px;
     font-weight: bold;
     margin-bottom: 30px;
  }
  .cuerpo-textocon {
    margin-top: 100px;
    width: 450px;
  
  }
  
  #nombrecontacto, #apellidocontacto, #emailcontacto, #celularcontacto {
   width: 100%;
   height: 30px;
  } 
  .opcion {
    margin-top: 10px;
  }
  .asterisco {
    margin-top: 5px;
  }

  .cuerpotexto1con {
  text-align: justify;
  }
  .cont-mapa {
    margin-left: 4%;
    text-align: center;
    /* background-color: chartreuse; */
  }

  .mapa {
    
    margin-top: 10%;  
    width: 400px;
    height: 350px;
    border: 0;
  }
  .formulario {
    
    font-weight: bold;
    margin:0px auto;
    background-color: rgba(224,224,224,0.3);
    transition: 0.3s;
    
  }
  .mensajecontacto {
    margin-bottom: 10px;
  }
  #mensajecontacto {
    width: 100%;
  }
  .telefonos {
    /* background-color: seagreen; */
    margin-left: 3%;
    text-align: center;
  
  }
  
  .eslogan1con {
    margin:0px auto;
  }
  .botonm {
    color: #070707;
    background-color: #fff;
    outline: none;
    border: solid 2px#C8C8C8;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    font-weight: bold;
    margin-bottom: 5%;

  }
.botonm:hover {
  color:  #fff;
  background-color: rgb(35, 80, 69)

}
.accionesb {
  text-align: center;
}
.titulotlf {
  color: #070707;
  font-weight: bold;
  font-size: 33px;
}
.numerotlf {
  color: #070707;
  font-weight: bold;
  font-size: 18px;
  

}
.cont-correo {
  margin-top: 20px;
}
.contcorreo {
  color: #070707;
  font-weight: bold;
  font-size: 33px;
  
}
.correo1 {
  color: #0d0aa3;
  font-weight: bold;
  font-size: 13px;
}
.socialesc {
  margin-top: 90px;
  font-size: 50px;
}
.titulomapa {
  margin-top: 40px;
  font-size: 30px;
  color: #070707;
  font-weight: bold;
  text-align: center;
  width: 400px;
}
