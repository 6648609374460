
h1{
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

}
.cont-footer-princ {
left: 30px !important; 
}

.cont-servi-principal  {
  width: 100%;
  margin: 0px;
  
}

#img1pre {
  position: relative;
  width: 400px;
  height: 200px;
  top: 30px;
  border:10px solid#2bbbad; 
 
  /* box-shadow: 0 2px 30px 0 rgba(43,187,173, 0.8);
  transition: 0.3s; */
}
#img2pre {
  position: absolute;
  width: 390px;
  height: 200px;
  top: 205px;
  left : 210px;
  z-index: 2;
  border:10px solid #2bbbad; 
  /* box-shadow: 0 2px 30px 0 rgba(43,187,173, 0.8);
  transition: 0.3s; */
}
#img3pre {
  position: absolute;
  width: 400px;
  height: 200px;
  top: 370px;
 
  z-index: 2;
  border:10px solid #2bbbad; 
  /* box-shadow: 0 2px 30px 0 rgba(43,187,173, 0.8);
  transition: 0.3s; */
}

.titulopre {
  position: absolute;
  margin-top: 20px;
   text-align: left;
   color: #070707;
   font-size: 40px;
   margin-bottom: 50px;
   margin-left: -150px;
   font-weight: bold;
}
.cuerpo-textopre {
  margin-top: 100px;
  width: 450px;
 
}

#cuerpotexto2pre, #cuerpotexto3pre, #cuerpotexto4pre, #cuerpotexto5pre, #cuerpotexto6pre, #cuerpotexto7pre, #cuerpotexto8pre, #cuerpotexto9pre {
 color: #FF69B4;
} 
.cuerpotexto1pre {
text-align: justify;
}

