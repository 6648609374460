
.cont-contenido, .conte {
  margin: 0;
  
   /* display: flex;  */

  min-height: 89vh;
  /* background-color: aqua; */
}

.cont-princi {
  display: block;
  position: relative;
  margin: 0;
  }

.cont-header{
  margin: 0%;
  padding: 0%;
}

.cont-contenedor{
  margin: 0%;
  /* margin-left:  -30px; */

  
}
.card{
   
  /* padding: 0px;  */
  /* margin: 0;
  position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem; */
}